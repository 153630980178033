.hero-section-container{
  height: 100vh;
}

.hero-section {
  height: 65vh;
  margin-top: 120px;

}
.hero-img {
  height: 35vw;
  width: 35vw;
}
.social-media-icons {
  font-size: 2em;
  color: rgb(130, 132, 150);
  transition: all 0.2s ease-in;
}
.about-text {
  font-size: 1.3rem;
  letter-spacing: 1.5px;
  word-spacing: 2px;
  font-family: Inter Tight, sans-serif;
  font-weight: 400;
  color: rgb(130, 132, 150);
}
.greeting-text {
  font-size: 2rem;
  font-weight: 200;
  font-family: Inter Tight, sans-serif;
  margin: 0;
}
.name-text {
  font-size: 3rem;
  font-weight: 500;
  font-family: Inter Tight, sans-serif;
  margin: 0;
}
.hero-text {
  width: 30vw;
}
.social-media-icons:hover {
  cursor: pointer;
}
.social-media-icons.lin:hover {
  color: #0077b5;
}
.social-media-icons.ig:hover {
  color: #e1306c;
}
.social-media-icons.mail:hover {
  color: #249ee4;
}
.social-media-icons.git:hover {
  color: black;
}

.resume-link {
  color: black;
  text-decoration: none;
}
.resume-link:hover {
  color: white;
}


@media screen and (max-width : 1500px) {
  .hero-img{
    height: 40vw;
    width: 40vw;
  }
}
@media screen and (max-width: 1305px) {
  .hero-img {
    height: 35vw;
    width: 35vw;
  }
  .social-media-icons {
    font-size: 1.5em;
  }
  .greeting-text {
    font-size: 1.7rem;
  }
  .name-text {
    font-size: 2.7rem;
  }
  .about-text {
    font-size: 1.1rem;
  }
  .hero-text{
    width: 45vw;
  }
}

@media screen and (max-width : 1135px) {
  .hero-img{
    width: 38vw;
    height: 38vw;
  }
  .hero-text{
    width: 42vw;
  }
}
@media screen and (max-width: 1070px) {
  .name-text {
    font-size: 2.5rem;
  }
  .about-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 980px) {
  .hero-img {
    height: 40vw;
    width: 40vw;
  }
  .hero-text{
    width: 35vw;
  }
  .social-media-icons {
    font-size: 1.5em;
  }
  .greeting-text {
    font-size: 1.5rem;
  }
  .name-text {
    font-size: 2.3rem;
  }
  .about-text {
    font-size: 1rem;
  }
}
@media screen and (max-width: 920px) {
  .hero-section-container{
    height: 125vh;
  }
  .hero-section{
    flex-direction: column;
    align-items: flex-start;
  }
  .social-media-icons {
    font-size: 1.8em;
  }
  .greeting-text {
    font-size: 2rem;
  }
  .about-text {
    font-size: 1.1rem;
  }
  .name-text {
    font-size: 3rem;
  }
  .hero-text {
    width: 70vw;
  }
  .hero-section {
    height: max-content;
    /* border: 1px solid lightgreen; */
    /* margin-top: 300px; */
  }
  .sub-btn {
    font-size: 1.3rem;
  }
  .hero-img{
    width: 50vh;
    height: 50vh;
  }
}

@media screen and (max-width: 781px) {
  .greeting-text {
    font-size: 2rem;
  }

  .about-text {
    font-size: 1.1rem;
  }

  .name-text {
    font-size: 2.5rem;
  }

  .hero-text {
    width: 75vw;
  }
  
}
@media screen and (max-width: 610px) {
  .greeting-text {
    font-size: 1.9rem;
  }

  .about-text {
    font-size: 1.1rem;
  }

  .name-text {
    font-size: 2.3rem;
  }

  .hero-img{
    height: 45vh;
    width: 45vh;
  }
}
@media screen and (max-width: 495px) {
  .greeting-text {
    font-size: 1.7rem;
  }

  .about-text {
    font-size: 1.1rem;
  }

  .name-text {
    font-size: 2rem;
  }

  .hero-text {
    width: 70vw;
  }
  .hero-img{
    height: 40vh;
    width: 40vh;
  }
}
@media screen and (max-width: 420px) {
  .greeting-text {
    font-size: 1.4rem;
  }

  .about-text {
    font-size: 1rem;
  }

  .name-text {
    font-size: 1.7rem;
  }

  .hero-text {
    width: 80vw;
  }
}


