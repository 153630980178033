/* DropdownMenu.css */
.fade-in {
    animation: fadeIn 0.2s forwards;
    opacity: 0;
}

.fade-out {
    animation: fadeOut 0.2s forwards;
    opacity: 1;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}