* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.projects-page-heading {
    font-size: 2rem;
    letter-spacing: 3px;

}

.projects-page-text {
    font-size: 1.3rem;
    letter-spacing: 1.3px;
    color: rgb(130, 132, 150);
    line-height: 1.4em;
    word-spacing: 1px;
}

@media screen and (max-width : 1400px ){
    .projects-page-heading {
        font-size: 1.8rem;
    }
        
    .projects-page-text {
        font-size: 1.2rem;
    }
}

@media screen and (max-width : 1200px) {
    .projects-page-heading {
        font-size: 1.6rem;
    }
        
    .projects-page-text {
        font-size: 1.1rem;
    }
}
@media screen and (max-width : 1000px) {
    .projects-page-heading {
        font-size: 1.4rem;
    }

    .projects-page-text {
        font-size: 1.05rem;
    }
}
@media screen and (max-width : 920px) {
    .projects-page-heading {
        font-size: 1.7rem;
    }

    .projects-page-text {
        font-size: 1.15rem;
    }
}
@media screen and (max-width : 420px) {
    .projects-page-heading {
        font-size: 1.5rem;
    }

    .projects-page-text {
        font-size: 1.05rem;
    }
}